const trimArray = (arr: Array<any> | null) => {
  const tempArr = arr;
  if (!tempArr) return tempArr;
  tempArr.forEach((i) => {
    if (tempArr[i]) {
      tempArr[i] = tempArr[i].trim();
    }
  });
  return tempArr;
};

const getPromotionPrices = (label?: string | number) => {
  let priceArr;
  let priceText;

  if (!label || typeof label !== 'string') {
    return {};
  }

  if (label.indexOf('%') !== -1) {
    // "20%"
    priceArr = trimArray(/^([\d\s]+)(%)[\s]?(.*)?$/.exec(label));

    priceText = Array.isArray(priceArr)
      ? {
          promotionPrice: priceArr[1],
          promotionSymbol: priceArr[2],
          promotionTail: priceArr[3],
        }
      : {};
  } else if (label.indexOf('/') !== -1) {
    // "69,00 /st", "69 /st", "69,00 /st +pant"
    priceArr = trimArray(/^([\d\s]+)[,.]?(\d+)?[\s]?(.*)?$/.exec(label));

    priceText = Array.isArray(priceArr)
      ? {
          promotionPrice: priceArr[1],
          promotionDecimal: priceArr[2] || '00',
          promotionTail: priceArr[3].split('  ')[1] || priceArr[3], // remove /st from  +pant
        }
      : {};
  } else if (label.indexOf('+') !== -1 || label.indexOf('kr') !== -1) {
    // "69,00 +pant", "69 +pant", "69 kr +pant",
    priceArr = trimArray(/^([\d\s]+)[,.]?(\d+)?[\s]?(.*)?$/.exec(label));

    priceText = Array.isArray(priceArr)
      ? {
          promotionPrice: priceArr[1],
          promotionDecimal: priceArr[2] || '00',
          promotionTail: priceArr[3],
        }
      : {};
  } else {
    // "69,00"
    priceArr = /^([\d\s]+)[,.]?(\d+)?$/.exec(label);

    priceText = Array.isArray(priceArr)
      ? {
          promotionPrice: priceArr[1],
          promotionDecimal: priceArr[2] || '00',
        }
      : {};
  }
  return priceText;
};

export default getPromotionPrices;
