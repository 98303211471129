import { createGlobalStyle, css } from 'styled-components';
import { rem } from 'polished';
import variables from '@styles/variables';
import Config from '@config';

export const StyledHotJarStyleChanger = createGlobalStyle<{ hide: boolean }>`
  ._hj-widget-container {
    ${Config.MEDIA.IS_MOBILE} {
      position: absolute !important;
      // Z-index should be below toolbar, since a lot of modals originate from toolbar
      z-index: ${variables.zIndex.toolbar - 1} !important;
    }
    
    ${(props) =>
      props.hide &&
      css`
        display: none !important;
      `}
  }
`;
