import styled, { css } from 'styled-components';
import { rem } from 'polished';
import variables from '@styles/variables';
import Config from '@config';
import Grid from '@atoms/Grid/Grid';
import Button from '@atoms/Button/Button';
import { Variant } from '@molecules/Carousel/Carousel';
import { DotStyle } from '@helpers/carouselHelper';
import { StyledImageGridComponent } from '@molecules/ImageGridComponent/ImageGridComponent.styles';

export const CarouselWrapper = styled.div`
  width: 100%;
`;

export const CarouselR = styled.div<{ variant: Variant }>`
  position: relative;
  ${(props) =>
    props.variant === 'buyAllProducts' &&
    css`
      ${Config.MEDIA.IS_MOBILE} {
        margin-left: ${rem(-20)};
        margin-right: ${rem(-20)};
      }
    `}
`;

export const StyledCarousel = styled.div<{ variant: Variant }>`
  ${(props) =>
    props.variant !== 'slotpicker' &&
    props.variant !== 'multisearch' &&
    props.variant !== 'buyAllProducts' &&
    props.variant !== 'lastBuy' &&
    css`
      border: ${rem(1)} solid ${variables.colors.mediumGray};
    `}
`;

export const CarouselSlide = styled.div<{ variant: Variant }>`
  position: relative;
  flex: 1 0 100%;
  transition: left ease 500ms;
  ${(props) =>
    props.variant === 'mixmatch' &&
    css`
      margin-top: ${rem(-1)};
      margin-bottom: ${rem(-1)};
      overflow: hidden;
    `}

  ${(props) =>
    props.variant === 'lastBuy' &&
    css`
      box-sizing: content-box;

      ${StyledImageGridComponent} {
        margin: 0;
      }
    `}
`;

export const CarouseSlideGroup = styled(Grid)<{ variant: Variant }>`
  margin: 0;
  display: flex;
  ${(props) =>
    props.variant === 'mixmatch' &&
    css`
      width: calc(100% + ${rem(1)});
      max-width: calc(100% + ${rem(1)});
      display: grid;

      ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
        margin: 0 auto;
      }
    `}

  ${(props) =>
    (props.variant === 'multisearch' || props.variant === 'buyAllProducts') &&
    css`
      width: 100%;
      display: grid;

      // Hack to increase height by 2 px to account for content-sized product boxes (border width x 2) in multisearch etc
      padding-bottom: ${rem(2)};

      ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
        margin: 0 auto;
      }
    `}

  ${(props) =>
    props.variant === 'lastBuy' &&
    css`
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: repeat(1, 1fr);

      ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
        margin: 0 auto;
        grid-template-columns: repeat(2, 1fr);
      }

      ${Config.MEDIA.FROM_DESKTOP_LARGE} {
        grid-template-columns: repeat(3, 1fr);
      }
    `}
  ${(props) =>
    props.variant === 'slotpicker' &&
    css`
      gap: ${rem(8)};
      padding: ${rem(1)};
    `}
`;

export const AnimatedContainer = styled.div<{ noArrowDesign: boolean }>`
  display: flex;
  position: relative;
  transition: left 0s cubic-bezier(0.65, 0, 0.35, 1);
  ${(props) =>
    props.noArrowDesign &&
    css`
      gap: ${rem(8)};
    `}
`;

export const CarouselButton = styled(Button)<{ hide: boolean; variant: Variant }>`
  display: ${(props) => (props.hide ? 'none' : 'block')};

  &:disabled {
    opacity: 0.1;
  }

  ${(props) =>
    props.variant === 'slotpicker' &&
    css`
      position: absolute;
      top: 50%;
      z-index: ${variables.zIndex[1]};

      transform: translateY(-50%);

      &:first-of-type {
        left: ${rem(-10)};
      }

      &:last-of-type {
        right: ${rem(-10)};
      }

      &:disabled {
        display: none;
      }
    `}
`;

export const Pagination = styled.div`
  max-width: ${rem(94)};
  position: relative;
  margin-top: ${rem(10)};
`;

export const PaginationDots = styled.div`
  position: relative;
  transition: left 0s cubic-bezier(0.65, 0, 0.35, 1);
  white-space: nowrap;
`;

export const PaginationDot = styled.span<{ state: DotStyle }>`
  width: ${(props) => rem(props.state.size)};
  height: ${(props) => rem(props.state.size)};
  border-radius: 100%;
  margin-left: ${rem(8)};
  display: inline-block;
  background-color: ${(props) => (props.state.active ? variables.colors.primary : variables.colors.mediumGray)};

  &:first-child {
    margin-left: 0;
  }

  ${(props) =>
    props.state.size === 4 &&
    css`
      margin-bottom: ${rem(2)};
    `}

  ${(props) =>
    props.state.size === 6 &&
    css`
      margin-bottom: ${rem(1)};
    `}
`;

export const StyledCarouselHeader = styled.div<{ variant: Variant }>`
  display: flex;
  justify-content: space-between;
  padding: ${rem(10)};
  align-items: center;
  border-bottom: ${rem(1)} solid ${variables.colors.mediumGray};

  ${Config.MEDIA.IS_MOBILE_SMALL} {
    padding-left: ${rem(20)};
  }

  ${(props) =>
    (props.variant === 'multisearch' || props.variant === 'buyAllProducts' || props.variant === 'lastBuy') &&
    css`
      padding: ${rem(10)} 0;
      border-bottom: none;
    `}
`;

export const StyledNavigationArrowsWrapper = styled.div`
  display: flex;
  gap: ${rem(15)};
`;

export const PaginationWrapper = styled.div`
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
`;
